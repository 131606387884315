import PublicLayout from "@layouts/public/components/PublicLayout";
import { serverSideTranslations } from "next-i18next/serverSideTranslations";
import { ReactElement } from "react";
import { GetStaticPropsContext } from "next";
import { CmsLayout } from "@modules/cms/types/CmsLayout";
import CmsLayouts from "@modules/cms/components/CmsLayouts";
import { useRouter } from "next/router";
import { prefetchVehicleFilterData } from "@modules/vehicle/hooks/useVehicleFilterData";
import { dehydrate } from "@tanstack/react-query";
import { getServerSideQueryClient } from "@modules/api/queryClients";
import getCmsPageData from "@modules/cms/api/getCmsPageData";
import SeoInfo from "@common/components/SeoInfo";
import SeoInfoProps from "@common/types/SeoInfoProps";
import getCanonicalUrl from "@common/helpers/getCanonicalUrl";

type Props = {
  layouts: CmsLayout[];
  cmsSeoInfo: SeoInfoProps | null;
};

const IndexPage = ({ layouts, cmsSeoInfo }: Props) => {
  const { locale } = useRouter();

  return (
    <>
      {cmsSeoInfo && <SeoInfo seoInfo={cmsSeoInfo} />}
      <CmsLayouts locale={locale} layouts={layouts ?? []} />
    </>
  );
};

IndexPage.pageName = "IndexPage";
IndexPage.getLayout = (page: ReactElement) => (
  <PublicLayout>{page}</PublicLayout>
);

export const getStaticProps = async (context: GetStaticPropsContext) => {
  const queryClient = getServerSideQueryClient();

  const { layouts, seoInfo } = await getCmsPageData(
    "home",
    context.preview,
    context.locale?.toUpperCase()
  );

  if (seoInfo && !seoInfo.canonical) {
    seoInfo.canonical = getCanonicalUrl("home");
  }
  if (seoInfo && seoInfo.opengraphUrl) {
    seoInfo.opengraphUrl = getCanonicalUrl("home");
  }

  await prefetchVehicleFilterData(queryClient);

  return {
    props: {
      layouts,
      cmsSeoInfo: seoInfo,
      ...(await serverSideTranslations(context.locale ?? "en", [
        "common",
        "indexPage",
        "locationPage",
      ])),
      dehydratedState: dehydrate(queryClient),
    },
  };
};

export default IndexPage;
